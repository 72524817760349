<script setup>
import { ref, onMounted, defineProps, defineEmits, watch } from 'vue';
// import type { DropdownPosition, Iterable } from '@/types';

const position = ref({});

function getPosition() {
  console.log('got props!', props);
    const boundingRect = props.anchor.getBoundingClientRect();
    position.value = {
        left: boundingRect.left,
        top: boundingRect.bottom + 1,
        // width: '0px', //boundingRect.right - boundingRect.left
    }
}

const props = defineProps(['anchor', 'message'])
const emit = defineEmits(['select'])

const block = () => emit('block');
const unblock = () => emit('unblock');

watch(() => props.anchor, () => {
  getPosition();
})

// hooks + listeners
onMounted(getPosition);
window.addEventListener('resize', getPosition)
</script>

<template>
  <div 
    class="dropdown"
    :style="`top: ${position.top}px; left: ${position.left}px; `"
  >
    <p class="dropdown-option" @click="block">Block</p>
    <p class="dropdown-option" @click="unblock">Unblock</p>
    <!-- <p class="dropdown-option">Pin</p> -->
  </div>
</template>

<style scoped>
.dropdown {
  z-index: 10000000;
  width: 10rem; 
  max-height: 10rem;
  background-color: #fff;
  box-shadow: 0 0 20px rgba(0,0,0,.2);
  border-radius: .2rem;
  position: fixed;
  overflow: scroll;
}

.dropdown-option {
  padding: .5rem 1rem;
  color: #333;
}

.dropdown-option:hover, .selected {
  background-color: rgba(0,0,0,.05);
  cursor: pointer;
}
</style>
