<template style="background-color: #000;">
	<ProductModal 
		v-if="showProductModal" 
		:product="selectedProduct" 
		@select="addProductToCart"
		@close="toggleProductModal" 
		class="mobile-only"
	/>

	<Cart :isVisible="showCart" @close="toggleCart" />

	<div class="shopify-block">

		<div class="cart-tab" @click="toggleCart">
			<p>{{shopifyStore.cart.length}}</p>
			<img src="/svgs/cart.svg">
		</div>

		<div class="product-wrap">
			<div class="product-item" v-for="(p, key) in shopifyStore.products" :key="key">
				<div class="product-item-image" :style="`background-image: url(${p.image})`"></div>
				<h3 class="product-item-name">{{ p.product_title }}</h3>
				<h3 class="product-item-price">${{ p.variants[0].price.amount }}</h3>

				<button class="btn" @click="selectProduct(p)">View Product</button>
			</div>
		</div>
	</div>
</template>
<script setup>
import { onBeforeMount, ref } from 'vue';
import { getShopifyCollection, addToCheckout } from '@/services/shopify-service';
import { useShopifyStore } from '@/store/shopify';
import { useStore } from '@/store';
import ProductModal from './ProductModal.vue';
import Cart from './ShopifyCart.vue';

const store = useStore()
const shopifyStore = useShopifyStore();
const showProductModal = ref(false);
const selectedProduct = ref({});

const height = ref(0);

const showCart = ref(false);
const toggleCart = () => showCart.value = !showCart.value

window.addEventListener('resize', () => {
	if(window.innerWidth < 900) return;
	showCart.value = false;
})


const toggleProductModal = () => showProductModal.value = !showProductModal.value;

const selectProduct = p => {
	console.log('selected!!')
	selectedProduct.value = p;
	toggleProductModal();
}

const addProductToCart = async () => {

	console.log('adding to cart!')
	console.log(selectedProduct.value)

	console.log('the id is', shopifyStore.checkout.value);

	let data = await addToCheckout(store.tunecast.id, shopifyStore.checkout.id, {
		variantId: selectedProduct.value.selected_variant.id,
		quantity: +1
	});

	toggleProductModal()
	console.log(data);

	shopifyStore.cart = data;


	// const addToCheckout = async (domain, token, checkout_id, product) 
}

onBeforeMount(async () => {
	if(!shopifyStore.products);

	height.value = window.innerHeight;

	const shopifyProducts = await getShopifyCollection(
		store.tunecast.id
	);

	shopifyStore.products = shopifyProducts;
})

</script>

<style>

.cart-tab {
	position: absolute;
	left: 0;
	top: 20%;
	width: 3rem;
	height: 4.5rem;
	background-color: #fff;
	color: #000;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: .25rem;

	transition: all .2s ease;
}

.cart-tab:hover {
	opacity: .85;
	cursor: pointer;
}

.cart-tab img {
	height: 1.2rem;
}
</style>
<style type="text/css">
.cart-wrapper {
	position: fixed;
	display: flex;
	/*opacity: 0;*/
	width: 100vw;
	height: 100vh;
	z-index: 50000;
	top: 0;
	left: -100vw;
	transition: left .3s ease;
}

.cart {
	position: absolute;
	height: 70%;
	width: 70%;
	left: 0;
	bottom: 0;
	border-top-right-radius: 20px;
	background-color: #fff;
	padding: 2rem;
	display: block;
	flex-direction: column;
	color: #000;
}

.cart h2 {
	color: #000;
	text-decoration: underline;
}

.cart-products { 
	flex: 1;
}

.cart-product {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.cart-product h3 {
	font-weight: 700;
}

.cart-product img {
	height: 4rem;
}

.cart-footer h3 {
	font-weight: 200;
	display: flex;
	justify-content: space-between;
	margin-bottom: .8rem;
}

.cart .btn {
	width: 100%;
	font-size: 12px;
	color: #fff;
	padding: 1rem;
	background-color: #000;
	box-sizing: border-box;
	text-decoration: none;
	text-align: center;
}
</style>

<style type="text/css" scoped>
.shopify-block {
	min-width: 100vw;
	scroll-snap-align: center;
	min-height: 100%;
	display: none;
	flex-direction: column;
	position: relative;
	background-color: #000;
	/*overflow: hidden;*/
}


@media screen and (max-width: 900px) {
	.shopify-block {
		display: flex;
	}

	.cart-wrapper {
		display: block;
	}
}

.product-wrap {
	padding-top: 1rem;
	width: 100%;
	height: 100%;
	display: flex;
	flex-wrap: wrap;
	/*align-items: flex-start;*/
	justify-content: center;
	gap: 1.5rem;
	flex: 1;
	overflow-y: scroll;
}
 
.product-item {
	display: inline-block;
	text-align: center;
	/*margin: 0 rem;*/
	margin-bottom: 2.5rem;
	width: 35%;
}

.product-item-image {
	display: inline-block;
	height: 8.5rem;
	width: 8.5rem;
	margin-bottom: .7rem;
	background-size: cover;
	background-position: center;
}

.btn {
	margin-top: .7rem;
	background: rgba(255,255,255,1);
	color: #000;
	border: none;
	border-radius: 5px;
	padding: .4rem 1.5rem;
	transition: .1s ease all;
	font-weight: 600;
	font-size: 11px;
}

.btn:hover {
	opacity: .85;
	cursor: pointer;
}
</style>