<template>
<div class="settings-block">
	<div class="settings-block-inner">
		<br />
		<div class="settings-block-item" @click="toggleHost">
			<span>{{ LOCALE[store.tunecast.lang]['FILTERMESSAGES'] }}</span>
			<span><span :class="hostOnly && 'filter-active'">ON</span>/<span :class="!hostOnly && 'filter-active'">OFF</span></span>
		</div>
		<div class="settings-block-item" @click="toggleFAQ">
			<p>{{ LOCALE[store.tunecast.lang]['FAQHELP'] }}</p>
			<section v-if="showFAQ">
				<br />
				<p style="margin-bottom: 1rem; color: #9A9A9ABA; font-weight: 600; font-size: 11px;">{{ LOCALE[store.tunecast.lang]['FAQTROUBLE'] }}</p>


				<p v-html="LOCALE[store.tunecast.lang]['FAQAPPLE']"></p>

				<br />
				<p v-html="LOCALE[store.tunecast.lang]['FAQSPOTIFY']"></p>

				<!-- <p>For <b>Apple Music</b> users, try refreshing your browser and logging back into the listening experience.</p>

				<p style="margin-top: .5rem">Note* You must have an Apple Music account.</p>


				<br />
				<p>For <b>Spotify free</b> users, upon entering the listening experience, you will need to follow the pop up instructions to get redirected to Spotify. If you missed it, please refresh your browser and log back in. - From there, click shuffle to begin the audio playback.</p>

				<p style="margin-top: .5rem;">For <b>Spotify Premium</b> users, please make sure you’re logging into the Tunecast with the same account used on your Spotify app.</p> -->
			</section>
		</div>
		<br />
		
	</div>
</div>
</template>

<script setup>
import { useStore } from '@/store';
import LOCALE from '@/services/locale';

const store = useStore();
import { ref, defineProps, defineEmits, toRefs } from 'vue';

const props = defineProps(['hostOnly']);
const emit = defineEmits('toggleHost')
const { hostOnly } = toRefs(props);
const showFAQ = ref(false);
const toggleFAQ = () => showFAQ.value = !showFAQ.value;
const toggleHost = () => emit('toggleHost');
</script>

<style type="text/css" scoped>

.settings-block {
	user-select: none!important;
	min-width: 100vw;
	scroll-snap-align: center;
	height: 100%;
	display: none;
	background-color:#000;
}

.settings-block div {
	padding: 0 2rem;
}

.settings-block-inner {
	height: 100%;
	overflow-y: scroll;
}

.settings-block-item {
	padding: 1.2rem !important;
	border-radius: 10px;
	background-color: #17171B;
	margin-bottom: 1rem;
	font-size: 14px;
}

.settings-block-item:nth-child(2) {
	display: flex;
	justify-content: space-between;
}

.filter-active {
	font-weight: 700;
	color: #F3DC79;
}

@media screen and (max-width: 900px) {
	.settings-block {
		display: block;
	}
}
</style>