import { BACKEND_URL } from "./config";

const getShopifyCollection = async (tunecast_id) => {
  const res = await fetch(
    `${BACKEND_URL}/shopify?tunecast_id=${tunecast_id}`
  );
  let json = await res.json();
  return json.products.map((p) => ({
    product_title: p.title,
    qty: 1,
    product_id: p.id,
    variants: p.variants,
    description: p.descriptionHtml,
    image: p && p.images && p.images[0] && p.images[0].src,
    selected_variant: p.variants[0],
  }));
};

const getShopifyProduct = async (tunecast_id, product_id) => {
  const res = await fetch(
    `${BACKEND_URL}/shopify/product?product_id=${product_id}&tunecast_id=${tunecast_id}`
  );
  let json = res.json();

  return json.product;
};

const createCheckout = async (tunecast_id) => {
  const res = await fetch(`${BACKEND_URL}/shopify/checkout/create-checkout?tunecast_id=${tunecast_id}`, {
    method: 'POST'
  });
  let json = await res.json();

  return json.checkout;
};

const getCheckout = async (tunecast_id, checkout_id) => {
  const res = await fetch(`${BACKEND_URL}/shopify/checkout?tunecast_id=${tunecast_id}&checkout_id=${checkout_id}`);
  let json = await res.json();
  return json.checkout;
};

const addToCheckout = async (tunecast_id, checkout_id, product) => {
  const checkout_products = [product];

  const data = { checkout_id, checkout_products };

  const res = await fetch(
    `${BACKEND_URL}/shopify/checkout/add-products?tunecast_id=${tunecast_id}`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      ...data
    })
  });

  let json = await res.json();


  return json.checkout_products;
};

const removeFromCheckout = async (tunecast_id, checkout_id, lineItem_id) => {
  const res = await fetch(
    `${BACKEND_URL}/shopify/checkout/remove-products?tunecast_id=${tunecast_id}`, {
      method: 'PUT',
      body: JSON.stringify({
        checkout_id, lineItem_id
      })
    }
  );

  let json = await res.json();

  return json.checkout_products;
};

export {
  getCheckout,
  getShopifyCollection,
  getShopifyProduct,
  createCheckout,
  addToCheckout,
  removeFromCheckout,
};
