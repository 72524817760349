<template>
	<!-- mobile tracklist -->
	<div class="mobile-tracklist" id="exp-tracklist[mobile]">
		<div id="mobile-tracklist-buffer" ></div>
		<MobileTrackItem 
			v-for="(track, key) in tracks" 
			:title="track.name"
			:artist="track.artist"
			:artwork="track.artwork"
			:saved="track.saved"
			:duration="track.duration"
			:key="key"
			:index="key"
			:active="key === 0"
			@save="onSave"
		/>
		<div id="mobile-tracklist-buffer" ></div>
	</div>
</template>


<script setup>
import MobileTrackItem from './MobileTrackItem';
import { storeToRefs } from 'pinia';
import { ref, watch, onMounted } from 'vue';
import { useStore } from '@/store';
const store = useStore()
import { usePlayerStore } from '@/store/player'
const playerStore = usePlayerStore()
const { currentTrack } = storeToRefs(playerStore)

const tracks = ref([]);

let saveTrack;
onMounted(async () => {
	const spotifyService = await import('@/services/spotify-service');
	saveTrack = spotifyService.saveTrack;
})


const filterTracklist = () => {
	let index;
	for(index = 0; index < store.playlistTracks.length; index++)
		if(store.playlistTracks[index].id === currentTrack.value.id)
			break;

	let slice = store.playlistTracks.slice(index, store.playlistTracks.length);
	tracks.value = [
		...slice,
		...slice.length > 7 ? [] : store.playlistTracks.slice(0,7-slice.length)
	];
}
filterTracklist()

const onSave = async (index) => {
	console.log('saving', index)
	let track = index === -1 ? currentTrack.value : tracks.value[index];
	await saveTrack(store.accessToken, track.spotify_id);
	track.saved = true;
	store.playlistTracks.find(t => t.spotify_id === track.spotify_id)
	.saved = true;

	let _tracks = tracks.value.map(t => ({
		...t,
		...t.spotify_id === track.id ? {saved: true} : {}
	}))
	tracks.value = _tracks;
	console.log(tracks.value);
	console.log('we got the saved track!', track);
	return;
}

watch(currentTrack, filterTracklist)
</script>