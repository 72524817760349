<template>
	<div class="stream-wrapper">
		<div class="stream-overlay"></div>

		<div class="video-container">
			<video id="video-bkg" playsinline muted></video>
			<video id="video-player" playsinline muted></video>
		</div>
	</div>
</template>
<script setup>
import { onMounted } from 'vue';
import { useStore } from '@/store';
// import { playAudio, pauseAudio } from '@/services/spotify-service';
const store = useStore();

let socket = window.socket;

onMounted(async () => {
	const spotifyService = await import('@/services/spotify-service');
	const {playAudio,pauseAudio} = spotifyService;
	let IVSPlayerPackage = window.IVSPlayer;
    const PlayerState = IVSPlayerPackage.PlayerState;
    const PlayerEventType = IVSPlayerPackage.PlayerEventType;

    // Initialize player
    const player = IVSPlayerPackage.create();
    console.log("IVS Player version:", player.getVersion());
    player.attachHTMLVideoElement(document.getElementById("video-player"));

    socket.on('ls:talk', async () => {
		await pauseAudio();
		player.setVolume(1.0);
    })

    socket.on('ls:mute', async () => {
		player.setVolume(0.0);
		store.tunecast.start_time += 0;
		await playAudio();
    })
    
    // Attach event listeners
    player.addEventListener(PlayerState.PLAYING, function () {
      console.log("Player State - PLAYING");

		let frontVideo = document.getElementById('video-player')
		let stream;
		let fps = 0;
		let foregroundVideo = document.getElementById("video-bkg");
		if (frontVideo.captureStream) {
			stream = frontVideo.captureStream(fps);
		} else if (frontVideo.mozCaptureStream) {
			stream = frontVideo.mozCaptureStream(fps);
		} else {
			console.error('Stream capture is not supported');
			stream = null;
		}

		foregroundVideo.srcObject = stream;
		foregroundVideo.play();

		setTimeout(() => {
			frontVideo.muted = false;
		}, 5000)
    });
    player.addEventListener(PlayerState.ENDED, function () {
      console.log("Player State - ENDED");
    });
    player.addEventListener(PlayerState.READY, function () {
      console.log("Player State - READY");
    });
    player.addEventListener(PlayerEventType.ERROR, function (err) {
      console.warn("Player Event - ERROR:", err);
    });

    // Setup stream and play
    player.setAutoplay(true);
    player.load("https://5c854a1a637b.us-west-2.playback.live-video.net/api/video/v1/us-west-2.454500811863.channel.63RuerE74qZR.m3u8");
    player.setVolume(1.0);
})
</script>

<style type="text/css" scoped>
.stream-wrapper {
	position: relative;
	padding: 0 3rem;
	max-height: 100%;

	top: -2rem;
	position: fixed!important;


	height: 450px;
	width: 54vw;

	background-size: cover;
	top: 50%;
	left: 17%;
	margin-top: -240px; 
	margin-left: -140px;
	z-index: 50000000000;
}

.video-container {
	position: relative;
	border-radius: 20px;
	width: 120%;
	min-height: 80%;
	background-color: #000;
	height: 100%;
	display: flex;
	justify-content: center;
	overflow: hidden;


	height: 100%;
	width: 100%;
	border-radius: 0;
}

#video-player {
	height: 100%;
	/*width: 75%;*/
	position: relative;
	z-index: 5;
}

#video-bkg {
	width: 100%;
	object-fit: fill;
	position: absolute;
	z-index: 1;
	filter: blur(10px);
}

@media screen and (max-width: 900px) {
	.stream-wrapper {
		top: 0;
		left: 0;
		margin-top: 0; 
		margin-left: 0;

		/* height: 100vh; 
		width: 100vw; 
		z-index: 5!important; */


		top: 0;
		left: 0;
		padding: 0;
		height: 100vh;
		width: 100vw;
		z-index: 0;
	}

	.stream-overlay {
		z-index: 6;
		position: absolute;
		top: 10rem;
		left: 0;
		right: 0;
		bottom: 0;
		background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, .8) 100%);
	}
}
</style>