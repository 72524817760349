export function getCookie(name) {
  // Split cookie string and get all individual name=value pairs in an array
  var cookieArr = document.cookie.split(";");

  // Loop through the array elements
  for (var i = 0; i < cookieArr.length; i++) {
    var cookiePair = cookieArr[i].split("=");

    /* Removing whitespace at the beginning of the cookie name
        and compare it with the given string */
    if (name == cookiePair[0].trim()) {
      // Decode the cookie value and return
      return decodeURIComponent(cookiePair[1]);
    }
  }

  // Return null if not found
  return null;
}

export function getNamespace() {
  let ns = window.location.host.split('.')[0];
  return ns;
}

export const formatTime = ms => {
  let seconds = ~~(ms / 1000);
  let minutes = ~~(seconds / 60);
  seconds = seconds - (60 * minutes);

  return `${minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
};


// const padNumber = n => n < 10 ? '0'+n : n;

export function getCountdownString(startTime) {
  // Get today's date and time
  startTime = new Date(startTime).getTime();
  let now = new Date().getTime();

  // Find the distance between now and the countdown date
  let distance = startTime - now;

  if (distance <= 0) return '';

  // Time calculations for days, hours, minutes, and seconds
  let days = Math.floor(distance / (1000 * 60 * 60 * 24));
  let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  let seconds = Math.floor((distance % (1000 * 60)) / 1000);

  // Construct the countdown string, omitting any zero values
  let countdownString = '';
  if (days > 0) countdownString += `${days} days `;
  if (hours > 0) countdownString += `${hours} hours `;
  if (minutes > 0) countdownString += `${minutes} mins `;
  if (seconds > 0) countdownString += `${seconds}s`;

  return countdownString.trim();
}