<template>
<div class="mobile-track-item">
	<div class="mobile-track-item-inner">
		<div style="display: flex; flex-direction: row; align-items: center;">


<dotlottie-player v-if="props.active"
autoplay
loop
mode="normal"
src="/svgs/sound.lottie"
style="height: 1.35rem; width: 1.35rem; color: #fff; margin-right: .75rem"
>
</dotlottie-player>

			<img :src="artwork">
			<div class="mobile-track-meta">
				<h3 :style="props.active && 'color: #cc33ff'">{{props.title.slice(0,18)}}</h3>
				<h3>{{props.artist}}</h3>
			</div>
		</div>

		<div class="mobile-track-item-right">
			<p class="mobile-track-time">{{formatTime(props.duration)}}</p>
			<!-- <img src="/svgs/like.svg" style="height: 15px"> -->
			<div class="track-item-favorite" @click="emit('save', props.index)">
				<img v-if="!props.saved" src="/svgs/like.svg" style="height: 15px">
				<img v-if="props.saved" src="/svgs/liked.svg" style="height: 15px">
			</div>
		</div>
	</div>
</div>
</template>
<script setup>
//eslint-disable-next-line
import * as LottiePlayer from '@dotlottie/player-component';

import { defineProps, defineEmits } from 'vue'
const props = defineProps(['index', 'saved', 'active', 'title', 'artist', 'key', 'duration', 'artwork'])
const emit = defineEmits(['save']);
const formatTime = ms => {
	let seconds = ~~(ms / 1000);
	let minutes = ~~(seconds / 60);
	seconds = seconds - (60 * minutes);

	return `${minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
}
</script>
<style type="text/css" scoped>
.mobile-track-item {
	background: #17171B;
	border-radius: 10px;
	padding: .6rem .75rem;
	display: inline-block;
	min-width: 18rem;
}

.mobile-track-item-inner {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.mobile-track-item-right {
	flex: 1;
	text-align: right;
	display: flex;
	gap: 12px;
	justify-content: right;
	align-items: center;
}

.mobile-track-time {
	font-weight: 600;
	font-size: 14px;
}

.mobile-track-item img {
	height: 2.8rem;
}
.mobile-track-item .mobile-track-meta {
	color: #fff;
	margin-left: 1rem;
	text-align: left;
	font-size: 12px;
}
.mobile-track-item .mobile-track-meta h3:nth-child(2) {
	font-weight: 200;
}
</style>