<template>
	<div class="dark-overlay"></div>
	<div class="wrapper">
		<div class="content">
			<div class="card">
				<div class="card-body">
					<div class="x-wrap" @click="close">
						<img src="/svgs/x-vector.svg">
					</div>

					<h2>FAQ</h2>

					<p style="margin-bottom: 1rem; color: #9A9A9ABA; font-weight: 600; font-size: 12px;">{{ LOCALE[store.tunecast.lang]['FAQTROUBLE'] }}</p>

					<div class="content-block" v-html="LOCALE[store.tunecast.lang]['FAQAPPLE']">
					</div>

					<div class="content-block" style="margin-top: .9rem" v-html="LOCALE[store.tunecast.lang]['FAQSPOTIFY']">
					</div>

					<!-- <div style="padding: .5rem"></div>
					<input class="form-control" placeholder="Username" v-model="username">
					<div style="padding: .25rem"></div>
					<input class="form-control" placeholder="Email" v-model="email">
					<p style="color: #9A9A9A; margin: 0; text-align: left;">Subscribe to the mailing list</p>
					<br /><br />
					<button class="btn" @click="setUsername">Submit</button> -->
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { useStore } from '@/store'
const store = useStore()
import LOCALE from '@/services/locale';

import { defineEmits } from 'vue';
const emit = defineEmits(['close']);

const close = () => emit('close');

</script>

<style type="text/css" scoped>
.dark-overlay {
	position: fixed;
	z-index: 40000;
	height: 120%;
	width: 100vw;
	background-color: rgba(0,0,0,.4);
}

.content-block {
	background-color: #EBEBEB;
	color: #000;
	padding: 1rem;
	border-radius: 10px;
	text-align: left;
	font-size: 14px;
}
.x-wrap {
	position: absolute;
	left: .8rem;
	top: 1rem;
	background-color: #EBEBEB;
	display: inline-block;
	padding: .1rem .54rem;
	border-radius: 50%;
	transition: .1s ease all;
}

.x-wrap img {
	height: 8px;
	position: relative;
	top: -1px;
	left: 0px;
}

.x-wrap:hover {
	opacity: .7;
	cursor: pointer;
}

.wrapper {
	position: fixed;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #fff;
	height: 120%;
	width: 100vw;
	z-index: 50000;
	backdrop-filter: blur(10px);
}

.content {
	text-align: center;
}


.card {
	position: relative;
	background-color: #fff;
	min-height: 200px;
	width: 28rem;
	border-radius: 20px;
	color: #000;
}

.card-body {
	position: relative;
	padding: 1.5rem;
}


.card .btn {
	background: #000;
	color: #fff;
	border: none;
	width: 100%;
	border-radius: 5px;
	padding: .85rem;
	transition: .2s ease all;
}

.card h2 {
	/*padding-top: 1.5rem;*/
	margin: .5rem;
}

.btn p {
	padding: 0;
	margin: 0;
	font-size: .75rem;
	font-weight: 700;
}

.btn:hover {
	opacity: .9;
	cursor: pointer;
}

.form-control {
	background-color: #EBEBEB;
	width: 100%;
	box-sizing: border-box;
	border: none;
	padding: .85rem;
	color: #000;
	border-radius: 5px;
	outline: none !important;
}
</style>