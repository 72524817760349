<template>
    <div class="chat-bubble">
      <button class="bubble-btn" @click="togglePlay">
        <i v-if="isPlaying" class="fa fa-pause"></i>
        <i v-else class="fa fa-play"></i>
    </button>
      <div ref="waveform" class="waveform"></div>
      <audio ref="audio" :src="audioUrl" @play="onPlay" @pause="onPause"></audio>
    </div>
  </template>
  
  <script setup>
  import { useStore } from '@/store';
  import { ref, onMounted, onUnmounted, defineProps } from 'vue';
  import WaveSurfer from 'wavesurfer.js';
  const store = useStore();
  
  const props = defineProps({
    audioUrl: String
  });
  
  const audio = ref(null);
  const waveform = ref(null);
  const wavesurfer = ref(null);
  const isPlaying = ref(false);
  
  onMounted(() => {
    let _wavesurfer = WaveSurfer.create({
      container: waveform.value,
      waveColor: 'white',
      progressColor: 'purple',
      cursorColor: 'rgba(0,0,0,0)',
      barWidth: 2,
      barRadius: 1,
      responsive: true,
      height: 30,
      normalize: true,
      backend: 'WebAudio' // Ensure compatibility
    });

    wavesurfer.value = _wavesurfer;
  
    wavesurfer.value.load(props.audioUrl);
    wavesurfer.value.on('ready', () => {
      wavesurfer.value.setVolume(0); // initially mute the waveform playback

    });
  });
  
  onUnmounted(() => {
    wavesurfer.value.destroy();
  });
  
  const togglePlay = () => {
    if (!isPlaying.value) {
      wavesurfer.value.play();
      audio.value.play();
    } else {
      wavesurfer.value.pause();
      audio.value.pause();
    }
    isPlaying.value = !isPlaying.value;
  };
  
  const onPlay = () => {
    if(store.DSP === 'apple')
        window.apple_player.volume = .05;
    else
        window.spotify_player.setVolume(.05);
    isPlaying.value = true;
  };
  
  const onPause = () => {
    isPlaying.value = false;

    if(store.DSP === 'apple')
        window.apple_player.volume = 1;
    else
        window.spotify_player.setVolume(1);
  };
  </script>
  
  <style scoped>
  .chat-bubble {
    /* border: 1px solid #ccc; */
    margin: 5px;
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #17171B;
  }
  .bubble-btn {
    color: #fff;
    font-size: 14px;
    border: 1px solid #fff;
    border-radius: 50%;
    background: none;
    width: 50px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: .5rem;
  }

  .waveform {
    padding: 5px;
    margin-right: 10px;
    width: 100%;
    /* height: 10px; */
    overflow: hidden;
  }
  </style>
  