import { BACKEND_URL } from '@/services/config';
import { getNamespace } from '@/utils';

export const getTunecastTracks = async (tunecast_id) => {
  const res = await fetch(
    `${BACKEND_URL}/tunecast-tracks?tunecast_id=${tunecast_id}`
  );

  let json = await res.json();
  console.log(json);
  return json.tunecast_tracks;
};

export const getHandshake = async () => {
	// Get the query string from the URL
	const queryString = window.location.search;
	const searchParams = new URLSearchParams(queryString);
	const preview = searchParams.get('preview');

	let res = await fetch(`${BACKEND_URL}/v2/handshake?ns=${getNamespace()}${preview ? '&preview=true' : ''}`, {
		method: "GET",
		headers: {
			"Access-Control-Allow-Origin": "*",
		},
	});
	let data = await res.json();
	return data;
}