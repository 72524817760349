<template>
  
  <div v-if="scene === 0">
    <h2 style="color: #fff">Loading...</h2>
  </div>
  <Landing 
    v-if="scene === 1" 
    @authorized="setScene(2)"
  />
  <Experience v-if="scene === 2" />
  <div v-if="scene === 4">
    <h2 style="color: #fff">404</h2>
  </div>
</template>

<script setup>
import { ref, onBeforeMount, onBeforeUnmount } from 'vue';
import { useStore } from '@/store';
import { getNamespace, getCountdownString } from '@/utils';
import { WSS_URL } from '@/services/config';
import { getHandshake, getTunecastTracks } from '@/services/tunecast-service'
import Landing from './components/Landing'
import Experience from './components/Experience'
import io from 'socket.io-client';


window.dummyLog = [];

const store = useStore();

const scene = ref(0);
const setScene = val => scene.value = val;

window.addScript = (link) => {
  let tag = document.createElement("script");
  tag.src = link;
  document.getElementsByTagName("head")[0].appendChild(tag);
};

window.addEventListener('resize', () => {
  if(window.innerWidth > '900' && store.mobileDimensions)
    store.mobileDimensions = false;
  else if(window.innerWidth <= '900' && !store.mobileDimensions)
    store.mobileDimensions = true;
})

let countdownInterval;
onBeforeMount(async () => {
  let data = await getHandshake();
  if (!data.success) return setScene(4);
  console.log('got data!', data)
  setScene(1);
  let { appleToken } = data; 

  store.tunecastId = data.tunecast.id;
  store.styling = data.tunecast.css;
  let playlistTracks = await getTunecastTracks(data.tunecast.id);
  store.playlistTracks = playlistTracks.map(t => t.track);
  store.tunecastName = data.tunecast.name_public || data.tunecast.name;
  data.tunecast.start_time = new Date(data.tunecast.start_datetime).getTime();
  data.tunecast.end_time = new Date(data.tunecast.end_datetime ? data.tunecast.end_datetime : data.tunecast.start_datetime).getTime();
  store.tunecast = data.tunecast;
  store.tracks = data.tracks;
  console.log(data.tracks);
  console.log(data.tunecast);

  // load the custom styles
  let styleElement = document.createElement("style");
  styleElement.innerHTML = data.tunecast.css;
  document.head.appendChild(styleElement);

  window.registerApple(appleToken);
  // window.addScript("https://js-cdn.music.apple.com/musickit/v1/musickit.js");

  window.addScript('https://js-cdn.music.apple.com/musickit/v2/musickit.js');
  window.socket = io(`${WSS_URL}?ns=${getNamespace()}`);
  countdownInterval = setInterval(() => {
    // counting down
    if(Date.now() < data.tunecast.start_time) {
      let str = getCountdownString(data.tunecast.start_time);
      console.log(str);
      store.countdownStr = str;
      if(str) store.state = 1;
      if(!str) {
        // check to see if its ended
        store.state = 2;
        return clearInterval(countdownInterval);
      }
    } else if(Date.now() > (data.tunecast.start_time + data.tunecast.duration) && !data.tunecast.loop) {
      store.state = 3;
    } else if(data.tunecast.loop && Date.now() > data.tunecast.end_time) {
      store.state = 3;
    } else {
      store.state = 2;
    }
  }, 1000)
})

onBeforeUnmount(() => {
  clearInterval(clearInterval);
})



/**
 * ==================================
 *      APPLE
 * ==================================
 */

window.apple_player;
window.registerApple = (token) => {
  document.addEventListener("musickitloaded", async () => {
    console.log('window music kit?', window.MusicKit)

    // window.MusicKit.configure({
    //   developerToken: token,
    //   app: {
    //     name: "Tunecast Apple Player",
    //     build: "1.0",
    //   },
    // });

    try {
      await window.MusicKit.configure({
        developerToken: token,
        app: {
          name: 'Tunecast Player',
          build: '1.4.1'
        }
      });
    } catch(err) {
      return console.log('error configuring music kit.');
    }

    window.apple_player = window.MusicKit.getInstance();
    window.apple_player.repeatMode = 2;
  });
};

</script>

<style>
* {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  border: 0;
  padding: 0;
  margin: 0;
}
body {
  background: #000;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
