import { ref } from 'vue';
import { defineStore } from 'pinia'
import { useStore } from './index';
// const store = useStore();

import { createCheckout, getCheckout } from '@/services/shopify-service';

let store;
let checkoutInterval;
// what is computed? 

export const useShopifyStore = defineStore('shopify', () => {
	if(!store) store = useStore();
	const products = ref([]);
	const checkout = ref({});
	const cart = ref([]);

	(async function() {
		if(checkout.value && checkout.value.id) return;
		checkout.value = await createCheckout(store.tunecast.id);

		if(!checkoutInterval) {
		// 	// check to see if cart checked out
			checkoutInterval = setInterval(async () => {
				let data = await getCheckout(store.tunecast.id, checkout.value.id);
				if(!data.completedAt) return;
				checkout.value = await createCheckout(store.tunecast.id)
				cart.value = [];
			}, 3 * 1e3)
		}
	})()

	
	return { checkout, products, cart }
})