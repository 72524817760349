<template>
<div class="content" id="exp-wrapper[content]">
	<!-- content -->
	<div class="bkg-outer">
		<div class="bkg" :style="`background-image: url(${store.tunecast.chat_artwork_image ? store.tunecast.chat_artwork_image : store.playlistTracks.length && store.playlistTracks[0] && store.playlistTracks[0].artwork});`">
		</div>
	</div>
	<div class="blur"></div>

	<nav id="exp-wrapper[nav]" style="position: relative; z-index: 4000;">
		<ul>
			<li><img class="logo" :src="store.tunecast.chat_logo_image" id="exp-image[desktop-chat-logo]"></li>
			<li><button :class="`btn ${view === 1 && 'active'}`" @click="toggleView(1)"><img src="/svgs/music.svg"> <span>{{ LOCALE[store.tunecast.lang]['PLAYLIST'] }}</span></button></li>
			<li v-if="store.tunecast.shopify"><button :class="`btn ${view === 2 && 'active'}`" @click="toggleView(2)"><img src="/svgs/shirt.svg"> <span>{{LOCALE[store.tunecast.lang]['MERCH']}}</span></button></li>

			
			<li v-if="store.tunecast.instagram_link"><a style="color: #fff;" :href="store.tunecast.instagram_link" target="_blank"><i class="fa-brands fa-instagram"></i></a></li>
			<li v-if="store.tunecast.twitter_link"><a style="color: #fff;" :href="store.tunecast.twitter_link" target="_blank"><i class="fa-brands fa-twitter"></i></a></li>
			<li v-if="store.tunecast.facebook_link"><a style="color: #fff;" :href="store.tunecast.facebook_link" target="_blank"><i class="fa-brands fa-facebook"></i></a></li>
			<li v-if="store.tunecast.snapchat_link"><a style="color: #fff;" :href="store.tunecast.snapchat_link" target="_blank"><i class="fa-brands fa-snapchat"></i></a></li>
			<li v-if="store.tunecast.discord_link"><a style="color: #fff;" :href="store.tunecast.discord_link" target="_blank"><i class="fa-brands fa-discord"></i></a></li>
		</ul>
	</nav>

	<div v-show="view === 1" style="display: flex; flex: 0; align-items: stretch; flex-direction: column; justify-content: center; min-height: calc(100% - (78px + 3rem));max-height: calc(100% - (78px + 3rem));">
		<div v-if="store.state === 1" class="countdown-wrapper">
			<h1>Live in</h1>
			<h1>{{store.countdownStr}}</h1>
		</div>

		<LiveStream v-if="store.state === 2 && store.livestreaming && !store.mobileDimensions" />

		<div v-if="store.state === 2 && !store.livestreaming" class="tracklist" id="exp-tracklist[desktop]">
			<br /><br /><br />
			<h1>{{store.tunecastName}}</h1>
			<p style="font-weight: 600;">{{store.playlistTracks.length}} {{LOCALE[store.tunecast.lang]['SONGS']}}</p>

			<br />
			
			<CurrentTrack 
				v-if="playerStore.currentTrack && playerStore.currentTrack.name"
				:title="playerStore.currentTrack.name"
				:artist="playerStore.currentTrack.artist"
				:artwork="playerStore.currentTrack.artwork"
				:duration="playerStore.currentTrack.duration"
				:saved="playerStore.currentTrack.saved"
				@save="onSave"
			/>


			<TrackItem 
				v-for="(track, key) in tracks" 
				:title="track.name"
				:artist="track.artist"
				:artwork="track.artwork"
				:duration="track.duration"
				:saved="track.saved"
				:index="key"
				:key="key"
				@save="onSave"
			/>
		</div>

		<div v-if="store.state === 3" style="margin-left: 4rem; position: relative;z-index: 10;">
			<h1>Ended</h1>
			<h1>Thanks for coming</h1>
		</div>
	</div>


	<Shopify v-if="view === 2" />
</div>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { ref, watch, onMounted } from 'vue';
import { useStore } from '@/store';
const store = useStore()
import { usePlayerStore } from '@/store/player'
const playerStore = usePlayerStore()
const { currentTrack } = storeToRefs(playerStore)

import TrackItem from './TrackItem.vue';
import CurrentTrack from './CurrentTrack.vue';
import Shopify from './Shopify';
import LiveStream from './LiveStream';
import LOCALE from '@/services/locale';
console.log(LiveStream)
let saveTrack;
onMounted(async () => {
	const spotifyService = await import('@/services/spotify-service');
	saveTrack = spotifyService.saveTrack;
})

const tracks = ref([]);

const onSave = async (index) => {
	let track = index === -1 ? currentTrack.value : tracks.value[index];
	await saveTrack(store.accessToken, track.spotify_id);
	track.saved = true;
	store.playlistTracks.find(t => t.spotify_id === track.spotify_id)
	.saved = true;

	let _tracks = tracks.value.map(t => ({
		...t,
		...t.spotify_id === track.id ? {saved: true} : {}
	}))
	tracks.value = _tracks;
	console.log(tracks.value);

	console.log('we got the saved track!', track);
	return;
}

const filterTracklist = () => {
	let index;
	for(index = 0; index < store.playlistTracks.length; index++)
		if(store.playlistTracks[index].id === currentTrack.value.id)
			break;

	let slice = store.playlistTracks.slice(index + 1, store.playlistTracks.length);
	tracks.value = [
		...slice,
		...slice.length > 7 ? [] : store.playlistTracks.slice(0,7-slice.length)
	];
}
filterTracklist()

watch(currentTrack, filterTracklist)
const view = ref(1);
const toggleView = (v) => view.value = v;
</script>
<style type="text/css" scoped>


/* ============
 * 
 * content
 *
 * =============
 */


.content {
	position: relative;
	width: 55%;
	height: 100%;
	display:flex;
	flex-direction: column;

	z-index: 4000;
}

.content .bkg-outer {
	width: 100%;
	height: 100%;
	position: absolute;
	z-index: 1;
	background-size: cover;
	overflow: hidden;
}

.content .bkg {
	width: 100%;
	height: 100%;
	position: absolute;
	z-index: 1;
	background-size: cover;
	filter: blur(20px);
}

.content .blur {
	position: absolute;
	z-index: 2;
	width: 100%;
	height: 100%;
	background-color: #fff;
	opacity: .5;
}

.countdown-wrapper {
	color: #000;
	font-size: 2.2rem;
	text-align: center;
	position: relative;
	z-index: 10;

	/*margin-top: 7.5rem;*/

	position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.countdown-wrapper h1:nth-child(1) {
	font-weight: 900;
	margin-bottom: -1.4rem;
}
.countdown-wrapper h1:nth-child(2) {
	font-weight: 200;
}

.tracklist {
	position: relative;
	z-index: 10;
	padding: 0 3rem;
	color: #000;
	width: 485px;

	--mask: linear-gradient(to bottom, 
		rgba(0,0,0, 1) 0,   rgba(0,0,0, 1) 50%, 
		rgba(0,0,0, 0) 97%, rgba(0,0,0, 0) 0
	) 100% 50% / 100% 100% repeat-x;

	-webkit-mask: var(--mask); 
	mask: var(--mask);

	flex: 1;
	overflow: hidden;
}

/**
 * NAV
 */
nav {
	margin: 1.5rem 3rem;
	position: relative;
	z-index: 5;
}

nav ul {
	display: flex;
	align-items: center;
}
nav ul li {
	display: inline;
	list-style-type: none;
	margin-right: .8rem;
}

nav .logo {
	height: 4.5rem;
}

nav ul li button img {
	height: .72rem;
	margin-right: .35rem;
}

nav ul li button {
	display: flex;
	align-items: center;
}


/* ============
 * 
 * other stuff
 *
 * =============
 */
.btn {
	background: rgba(0,0,0,.5);
	color: #fff;
	border: none;
	border-radius: 5px;
	padding: .6rem 1.65rem;
	transition: .1s ease all;
}

.btn:hover {
	opacity: .85;
	cursor: pointer;
}

.btn-gray {
	padding: .3rem .2rem;
	background: rgba(255,255,255,.2)
}

.btn.active {
	background: #000;
}


</style>