<template>
    <div class="cart-wrapper" :style="`height: ${height}px; ${showCart && 'left: 0'}`">
		<div class="cart" @click="toggleCart">
			<h2 class="cart-title">CART</h2>
			<br />
			<div class="cart-products">
				<div 
					class="cart-product"
					v-for="(product, key) in shopifyStore.cart"	
					:key="key"
				>
					<div>
						<img :src="product.variant.image.src">
					</div>

					<div>
						<!-- quantity -->
						<p>{{product.title}}</p>
					</div>

					<div>
						<h3>${{product.variant.price.amount}}</h3>
					</div>
				</div>
			</div>

			<div class="cart-footer">
				<h3>
					<span>SUBTOTAL</span>
					<span>${{shopifyStore.cart.reduce((a,b) => a + +b.variant.price.amount, 0)}}</span>
				</h3>
				<a style="width: 100%; display: block; text-decoration: none;" :href="shopifyStore.checkout.webUrl" target="_blank" class="btn">Checkout</a>
			</div>
		</div>
	</div>
</template>

<script setup>
import { onBeforeMount, ref, toRef, defineEmits, defineProps, watch } from 'vue';
import { useShopifyStore } from '@/store/shopify';

const props = defineProps({
  isVisible: String
});

const emit = defineEmits(['close'])

const showCart = toRef(props, 'isVisible');
const height = ref(0);
const shopifyStore = useShopifyStore();

onBeforeMount(async () => {
	height.value = window.innerHeight;
})

const toggleCart = () => emit('close');

watch(() => {
    console.log('new value for isVisible', props.isVisible)
})
</script>


<style type="text/css" scoped>
.cart-wrapper {
	position: fixed;
	display: block;
	/*opacity: 0;*/
	width: 100vw;
	height: 100vh;
	z-index: 50000000;
	top: 0;
	left: -100vw;
	transition: left .3s ease;
}

.cart {
	position: absolute;
	height: 70%;
	width: 70%;
    max-width: 500px;
	left: 0;
	bottom: 0;
	border-top-right-radius: 20px;
	background-color: #fff;
	padding: 2rem;
	display: flex;
	flex-direction: column;
	color: #000;
}

.cart h2 {
	color: #000;
	text-decoration: underline;
}

.cart-products { 
	flex: 1;
}

.cart-product {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.cart-product h3 {
	font-weight: 700;
}

.cart-product img {
	height: 4rem;
}

.cart-footer h3 {
	font-weight: 200;
	display: flex;
	justify-content: space-between;
	margin-bottom: .8rem;
}

.cart .btn {
	width: 100%;
	font-size: 12px;
	color: #fff;
	padding: 1rem;
	background-color: #000;
	box-sizing: border-box;
	text-decoration: none;
	text-align: center;
}
</style>

<style type="text/css" scoped>
.shopify-block {
	min-width: 100vw;
	scroll-snap-align: center;
	height: 100%;
	display: none;
	flex-direction: column;
	position: relative;
	/*overflow: hidden;*/
}

#cart-tab {
	position: absolute;
	left: 0;
	top: 20%;
	width: 3rem;
	height: 4.5rem;
	background-color: #fff;
	color: #000;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: .25rem;

	transition: all .2s ease;
}

#cart-tab:hover {
	opacity: .85;
	cursor: pointer;
}

#cart-tab img {
	height: 1.2rem;
}

@media screen and (max-width: 900px) {
	.shopify-block {
		display: flex;
	}

	.cart-wrapper {
		display: block;
	}
}

.product-wrap {
	padding-top: 1rem;
	width: 100%;
	height: 100%;
	display: flex;
	flex-wrap: wrap;
	/*align-items: flex-start;*/
	justify-content: center;
	gap: 1.5rem;
	flex: 1;
	overflow-y: scroll;
}
 
.product-item {
	display: inline-block;
	text-align: center;
	/*margin: 0 rem;*/
	margin-bottom: 2.5rem;
	width: 35%;
}

.product-item-image {
	display: inline-block;
	height: 8.5rem;
	width: 8.5rem;
	margin-bottom: .7rem;
	background-size: cover;
	background-position: center;
}

.btn {
	margin-top: .7rem;
	background: rgba(255,255,255,1);
	color: #000;
	border: none;
	border-radius: 5px;
	padding: .4rem 1.5rem;
	transition: .1s ease all;
	font-weight: 600;
	font-size: 11px;
}

.btn:hover {
	opacity: .85;
	cursor: pointer;
}
</style>