<template>
	<div class="dark-overlay"></div>
	<div class="wrapper">
		<div class="content">
			<div class="card">
				<div class="card-body">
					<div class="x-wrap" @click="close">
						<img src="/svgs/x-vector.svg">
					</div>

                    <img src="/spotify-logo.png" alt="" style="height: 2rem;">
					<h2>{{LOCALE[store.tunecast.lang]['FREEMODALTITLE']}}</h2>

					<div class="free-modal-text" v-html="LOCALE[store.tunecast.lang]['FREEMODALTEXT']">

					</div>

                    <a style="
                        padding: 1rem;
                        text-decoration: none;
                        width: 100%;
                        border-radius: 10px;
                        background-color: #000;
                        text-align: center;
                        color: #fff;
                        font-weight: 800;
                        display: block;
                        box-sizing: border-box;"
                        target="_blank"

                        :href="`https://open.spotify.com/playlist/${store.tunecast.spotify_playlist.split(':')[2]}`" 
                        
                    >
                    {{LOCALE[store.tunecast.lang]['FREEMODALBTN']}}
                    </a>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { defineEmits } from 'vue';
import { useStore } from '@/store';
import LOCALE from '@/services/locale';
const store = useStore();

const emit = defineEmits(['close']);

const close = () => emit('close');
</script>
<style>
.free-modal-text p {

	margin-bottom: 1rem; 
	color: #848484; 
	font-weight: 600; 
	font-size: 13px;

}
</style>
<style type="text/css" scoped>

.dark-overlay {
	position: fixed;
	z-index: 40000;
	height: 120%;
	width: 100vw;
	background-color: rgba(0,0,0,.4);
}

.content-block {
	background-color: #EBEBEB;
	color: #000;
	padding: 1rem;
	border-radius: 10px;
	text-align: left;
	font-size: 14px;
}
.x-wrap {
	position: absolute;
	left: .8rem;
	top: 1rem;
	background-color: #EBEBEB;
	display: inline-block;
	padding: .1rem .54rem;
	border-radius: 50%;
	transition: .1s ease all;
}

.x-wrap img {
	height: 8px;
	position: relative;
	top: -1px;
	left: 0px;
}

.x-wrap:hover {
	opacity: .7;
	cursor: pointer;
}

.wrapper {
	position: fixed;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #fff;
	height: 120%;
	width: 100vw;
	z-index: 50000;
	backdrop-filter: blur(10px);
}

.content {
	text-align: center;
}


.card {
	position: relative;
	background-color: #fff;
	min-height: 200px;
	width: 23rem;
	border-radius: 20px;
	color: #000;
}

.card-body {
	position: relative;
	padding: 1.5rem;
}

@media screen and (max-width: 750px) {
    .card {
        width: 100%;
    }
}


.card .btn {
	background: #000;
	color: #fff;
	border: none;
	width: 100%;
	border-radius: 5px;
	padding: .85rem;
	transition: .2s ease all;
}

.card h2 {
	/*padding-top: 1.5rem;*/
	margin: .5rem;
}

.btn p {
	padding: 0;
	margin: 0;
	font-size: .75rem;
	font-weight: 700;
}

.btn:hover {
	opacity: .9;
	cursor: pointer;
}

</style>