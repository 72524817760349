<template>
	<ProductModal 
		v-if="showProductModal" 
		:product="selectedProduct" 
		@select="addProductToCart"
		@close="toggleProductModal" 
	/>

	<!-- cart -->
	<Cart :isVisible="showCart" @close="toggleCart" />
	
	<div class="wrapper">

		<div class="cart-tab" @click="toggleCart">
			<p>{{shopifyStore.cart.length}}</p>
			<img src="/svgs/cart.svg">
		</div>

		<div class="product-item" v-for="(p, key) in shopifyStore.products" :key="key">
			<div class="product-item-image" :style="`background-image: url(${p.image})`"></div>
			<h3 class="product-item-name">{{ p.product_title }}</h3>
			<h3 class="product-item-price">${{ p.variants[0].price.amount }}</h3>

			<button class="btn" @click="selectProduct(p)">View Product</button>
		</div>
	</div>
</template>
<script setup>
import { onBeforeMount, ref } from 'vue';
import { getShopifyCollection, addToCheckout } from '@/services/shopify-service';
import { useStore } from '@/store';
import { useShopifyStore } from '@/store/shopify';
import ProductModal from './ProductModal.vue';
import Cart from './ShopifyCart.vue';


const showCart = ref(false);
const toggleCart = () => showCart.value = !showCart.value

const store = useStore();
const shopifyStore = useShopifyStore();
const showProductModal = ref(false);
const selectedProduct = ref({});

const toggleProductModal = () => showProductModal.value = !showProductModal.value;

onBeforeMount(async () => {
	// should not get here without loaded tunecast
	console.log(store.tunecast)
	if(!store.tunecast || !store.tunecast.id) return;
	// if(!shopifyStore.products);
	console.log('getting products');
	const shopifyProducts = await getShopifyCollection(store.tunecast.id);
	console.log('got priducts', shopifyProducts)
	shopifyStore.products = shopifyProducts;
})


const selectProduct = p => {
	console.log('selected!!')
	selectedProduct.value = p;
	toggleProductModal();
}

const addProductToCart = async () => {
	let data = await addToCheckout(store.tunecast.id, shopifyStore.checkout.id, {
		variantId: selectedProduct.value.selected_variant.id,
		quantity: +1
	});

	toggleProductModal()
	shopifyStore.cart = data;


	// const addToCheckout = async (domain, token, checkout_id, product) 
}

</script>

<style type="text/css" scoped>
.wrapper {
	padding: 0 3rem;
	position: relative;
	z-index: 30000;
	flex: 1;
	overflow: scroll;
	max-width: 80%;
}

.product-item {
	display: inline-block;
	text-align: center;
	margin: 0 2rem;
	margin-bottom: 2.5rem;
	width: 34%;
}

.product-item-image {
	display: inline-block;
	height: 10.5rem;
	width: 10.5rem;
	margin-bottom: .7rem;
	background-size: cover;
	background-position: center;
}

.btn {
	margin-top: .7rem;
	background: rgba(0,0,0,1);
	color: #fff;
	border: none;
	border-radius: 5px;
	padding: .6rem 2rem;
	transition: .1s ease all;
	font-weight: 600;
}

.btn:hover {
	opacity: .85;
	cursor: pointer;
}
</style>